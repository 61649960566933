<template>
    <div class="reserveren">

        <navigation></navigation>

        <div id="container">
            <div class="slider-wrap showcase om-section block-0" id="slider-9ae29c08a50641602b510d16b5084bcc">
                <ul
                    class="slider arrows arrows-hidden dots dots-default"
                    data-slider-speed="5000"
                    data-height="fixed-ratio"
                    data-att="35">
                    <li class>
                        <figure>
                            <img alt="Brasserie Den Anker" src="../assets/img/DenAnker6-min.jpg">
                            <figcaption>Brasserie Den Anker</figcaption>
                        </figure>
                    </li>
                </ul>
                <div class="overlay-wrap fixed-center">
                    <section class="overlay">
                        <h1>Cadeaubon</h1>
                        <p>
                            <router-link class="button" to="/reserveren">Reserveren</router-link>
                        </p>
                    </section>
                </div>
            </div>

            <div
                style="margin-bottom: 30px"
                class="content-wrap om-section block-1"
                id="content-9ae29c08a50641602b510d16b5084bcc"><a
                class="anchor scrollto-anchor"
                id="scrollto-anchor-1"></a>
                <section class="content">
                    <h1 class="entry-title"><span>Cadeaubon</span></h1>
                    <div id="MainScreen" align="center">
                        <div border="0" id="tableShop">
                            <div id="tdFoldersAndProducts" valign="top">
                                <div style="display:inline" id="AJAX_____Panel2">
                                    <div border="0" cellpadding="0" id="tableFoldersAndProducts">
                                        <div valign="top" align="center" id="tdProducts">
                                            <table
                                                id="Assets"
                                                class="Panel"
                                                border="0"
                                                cellspacing="0"
                                                cellpadding="0"
                                                style="max-width:400px;">
                                                <tbody>
                                                <tr class="table-heading Panel_Top_Row_Title">
                                                    <td class="Panel_Top_Left_Cell_Title" width="20">&nbsp;&nbsp;</td>
                                                    <td class="Panel_Top_Middle_Cell_Title">
                                                        <div width="100%" border="0" id="tableProducts">
                                                            <div align="left" id="TitleTD">Webshop
                                                            </div>
                                                            <div
                                                                align="right"
                                                                valign="middle"
                                                                id="SearchTD"
                                                                style="display: none;">
                                                                <form
                                                                    action="WS_Shop.asp"
                                                                    name="frmPOSButtons"
                                                                    id="frmPOSButtons"
                                                                    method="post"
                                                                    style="padding:0; margin: 0;">
                                                                    <input
                                                                        type="button"
                                                                        name="btnPOSButtonsSubmit"
                                                                        id="btnPOSButtonsSubmit"
                                                                        style="display:none; height:0; width:0;">
                                                                    <input
                                                                        type="text"
                                                                        size="12"
                                                                        name="ftxtSearch"
                                                                        id="pos_ftxtSearch"
                                                                        value=""
                                                                        height="0">
                                                                    <input
                                                                        type="submit"
                                                                        id="WS_SHOP_SearchButton"
                                                                        value="Zoeken"
                                                                        height="0">
                                                                    <a href="#ClearSearch"></a>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="Panel_Top_Right_Cell_Title" width="20">&nbsp;&nbsp;</td>
                                                </tr>
                                                <tr class="Panel_Middle_Row">
                                                    <td class="Panel_Middle_Left_Cell" width="20">&nbsp;</td>
                                                    <td class="Panel_Middle_Middle_Cell">
                                                        <table class="SHOP_TABLE_AssetGrid" width="100%" border="0">
                                                            <tbody>
                                                            <tr>
                                                                <td valign="top">
                                                                    <div class="SHOP_DIV_Asset">
                                                                        <table class="SHOP_TABLE_Asset" width="100%">
                                                                            <tbody>
                                                                            <tr v-for="giftcard in giftcards">
                                                                                <td>Cadeaubon € {{
                                                                                        giftcard.amount
                                                                                    }}
                                                                                </td>
                                                                                <td align="right" nowrap="">€
                                                                                    {{ giftcard.amount }}
                                                                                </td>
                                                                                <td width="10" nowrap="">
                                                                                    <a
                                                                                        class="add-order-item"
                                                                                        @click.prevent="addGiftcard(giftcard.amount)"></a>
                                                                                </td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td valign="top">
                                                                    <div class="SHOP_DIV_Asset">
                                                                        <table class="SHOP_TABLE_Asset" width="100%">
                                                                            <tbody>
                                                                            <tr>
                                                                                <td>Cadeaubon vrij bedrag</td>
                                                                                <td align="right" width="10" nowrap="">
                                                                                    <span style="white-space: nowrap">€&nbsp;
                                                                                        <input
                                                                                            type="text"
                                                                                            size="4"
                                                                                            v-model="freePrice"
                                                                                            class="SHOP_FreePrice"
                                                                                            style="padding: 0 10px;text-align: right">
                                                                                    </span>
                                                                                </td>
                                                                                <td width="10" nowrap="">
                                                                                    <span style="white-space: nowrap;">
                                                                                    <a
                                                                                        class="add-order-item"
                                                                                        @click.prevent="addGiftcard(freePrice)">
                                                                                        <img
                                                                                            src="/code/_Images/add.png"
                                                                                            border="0"
                                                                                            title="Toevoegen aan winkelmandje"></a>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td class="Panel_Middle_Right_Cell">&nbsp;</td>
                                                </tr>
                                                <tr class="Panel_Bottom_Row">
                                                    <td class="Panel_Bottom_Left_Cell">&nbsp;</td>
                                                    <td class="Panal_Bottom_Middle_Cell">&nbsp;</td>
                                                    <td class="Panel_Bottom_Right_Cell">&nbsp;</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tdShoppingCart" width="250" valign="top" align="center">
                                <div style="display:inline" id="AJAX_____Panel1">
                                    <table
                                        id="ShoppingBasket"
                                        class="Panel"
                                        border="0"
                                        cellspacing="0"
                                        cellpadding="0"
                                        style="max-width:250px;">
                                        <tbody>
                                        <tr class="table-heading Panel_Top_Row_Title">
                                            <td class="Panel_Top_Left_Cell_Title" width="20">&nbsp;&nbsp;</td>
                                            <td class="Panel_Top_Middle_Cell_Title">Winkelmandje</td>
                                            <td class="Panel_Top_Right_Cell_Title" width="20">&nbsp;&nbsp;</td>
                                        </tr>
                                        <tr class="Panel_Middle_Row">
                                            <td class="Panel_Middle_Left_Cell" width="20">&nbsp;</td>
                                            <td class="Panel_Middle_Middle_Cell">
                                                <div name="SHOPPINGCART" id="SHOPPINGCART">
                                                    <div v-if="cartGiftcards.length">
                                                        <table class="SHOP_TABLE_ShoppingCart" width="100%" border="0">
                                                            <tbody>
                                                            <tr v-for="(cartGiftcard, index) in cartGiftcards">
                                                                <td colspan="2">
                                                                    <div class="SHOP_DIV_OrderItem">
                                                                        <table class="SHOP_TABLE_Asset" width="100%">
                                                                            <tbody>
                                                                            <tr class="WS_OrderItemType10">
                                                                                <td align="left" width="1"><a
                                                                                    class="delete-order-item"
                                                                                    @click.prevent="CancelOrderItem(index)"><img
                                                                                    src="/Code/_Images/minus.gif"
                                                                                    border="0"></a></td>
                                                                                <td align="left" colspan="2">Cadeaubon €
                                                                                    {{ cartGiftcard.amount }}
                                                                                </td>
                                                                                <td align="right" nowrap="">€
                                                                                    {{ cartGiftcard.amount }}
                                                                                </td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="2">
                                                                    <hr>
                                                                </td>
                                                            </tr>
                                                            <tr class="TotalLine pt-3">
                                                                <td align="left"><b>Totaal:</b></td>
                                                                <td align="right"><b>€ {{ totalGiftcards }}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="3"><br>
                                                                    <label for="">Email</label>
                                                                    <span style="white-space: nowrap">
                                                                        <input
                                                                            type="text"
                                                                            v-model="emailInput"
                                                                            class="SHOP_FreePrice"
                                                                            style="text-align: left; padding: 0 10px">
                                                                    </span>
                                                                    <v-btn
                                                                        v-if="email"
                                                                        class="button"
                                                                        @click.prevent="generateGiftCard"
                                                                        :loading="loading"
                                                                        id="om-field-submit"
                                                                        depressed
                                                                        style="margin-top: 15px"
                                                                        large>Doorgaan
                                                                    </v-btn>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div v-else>
                                                        <p class="SHOP_EmptyBasket"><br>Uw winkelmandje is leeg</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="Panel_Middle_Right_Cell">&nbsp;</td>
                                        </tr>
                                        <tr class="Panel_Bottom_Row">
                                            <td class="Panel_Bottom_Left_Cell">&nbsp;</td>
                                            <td class="Panal_Bottom_Middle_Cell">&nbsp;</td>
                                            <td class="Panel_Bottom_Right_Cell">&nbsp;</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>

        </div>

        <app-footer></app-footer>

        <svg id="icon-sprite-social">
            <defs>
                <symbol id="icon-facebook">
                    <path d="M3.7 10h2.1V5h1.4l.2-1.7H5.8v-.9c0-.4 0-.7.7-.7h.9V0H5.9C4.3 0 3.7.8 3.7 2.2v1h-1V5h1v5z"/>
                </symbol>
            </defs>
        </svg>

    </div>
</template>

<script>
import AppFooter  from '../components/Footer'
import Navigation from '../components/Navigation'

export default {
    name: 'Cadeaubon',
    components: {Navigation, AppFooter},
    data() {
        return {
            loading: false,
            emailInput: '',
            giftcards: [
                {amount: 25},
                {amount: 50},
                {amount: 75},
            ],
            cartGiftcards: [],
            freePrice: 0,
        }
    },
    mounted() {
        $(window).scrollTop(0)
    },
    methods: {
        addGiftcard(amount) {
            if (amount > 0 && this.cartGiftcards.length < 1) {
                this.cartGiftcards.push({amount: amount})
            }else{
                alert('Er kan maar maximum 1 cadeaubon gelijktijdig besteld worden.')
            }
        },
        CancelOrderItem(index) {
            this.cartGiftcards.splice(index, 1)
        },
        generateGiftCard() {
            this.loading = true
            if (this.totalGiftcards > 0) {
                this.$axios.post('api/generateGiftCard', {
                    responseType: 'arraybuffer', // important
                    giftCards: this.cartGiftcards,
                    email: this.emailInput
                }).then(res => {
                    this.loading = false
                    let checkout = res.data._links.checkout.href
                    window.location.replace(checkout)
                }).catch(err => {
                    console.log(err)
                })
            }
        }
    },
    computed: {
        totalGiftcards() {
            return this.cartGiftcards.reduce((total, giftcard) => parseInt(total) + parseInt(giftcard.amount), 0)
        },
        email() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailInput)) {
                return true
            }
            return false
        }
    }

}
</script>

<style scoped>
.slider-wrap figure {
    min-height: 65vh;
}

#container .slider-wrap figure img {
    height: 65vh !important;
}

blockquote, hr {
    margin: 0;
    padding-bottom: 10px
}

hr {
    border: 0;
    border-top: 1px solid #ccc;
    height: 1px;
    width: 100%;
}

.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: #a09174 !important;
    font-family: "Muli", sans-serif !important;
    font-size: 18px !important;
    color: #fff;
}
</style>
